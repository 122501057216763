$(function () {
    theme();
    owls();
});

function theme() {
    var $body = $('body');
    var $header = $('.header');
    var $menuNavToggle = $('.menu-nav-toggle');
    var $hamburgerToggle = $('.hamburger-toggle');
    var $removeMask = $('.remove-mask');

    $menuNavToggle.on('click', function () {
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $hamburgerToggle.on('click', function () {
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $removeMask.on('click', function () {
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $('.header').stickyHeader({
        hideAfter : 10, 
        showAt    : 0,
        animation : 'slide',
        speed     : 200
    });
}

function owls() {
    console.log('owls');
    lazyloadOwl($('.owl-treatment'));
    lazyloadOwl($('.owl-team'));
}

function lazyloadOwl($owl) {
    console.log('lazyloadOwl');
    if ($owl.length > 0) {
        // $owl.on('inview', function (event, isInView) {
        //     if (isInView) {
        //         if ($owl.data('isEnable') != true) {

        //             $owl.data('isEnable', true);


        //         }
        //     }
        // });

        $owl.owlCarousel({
            loop: true,
            margin: 10,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                769: {
                    items: 2,
                    nav: false
                },
                992: {
                    items: 4,
                    nav: false,
                    loop: false
                }
            }
        });

        $('.prev-btn').click(function () {
            $owl.trigger('prev.owl.carousel');
        });
        $('.next-btn').click(function () {
            $owl.trigger('next.owl.carousel');
        });

        owlSliderEnableLazyload($owl);

    } 

}