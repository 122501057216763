(function($) {
    $.fn.stickyHeader = function(options) {

        // Default settings
        var settings = $.extend({
            hideAfter : 10, // Scroll distance in % to hide header
            showAt    : 0,  // Scroll distance in % to show header
            animation : 'slide', // Animation type: slide or fade
            speed     : 200 // Animation speed
        }, options);

        return this.each(function() {
            var $header = $(this);
            var headerHeight = $header.outerHeight();
            var lastScrollTop = 0;

            $(window).scroll(function() {
                var st = $(this).scrollTop();
                var hideAfterDistance = ($(document).height() * settings.hideAfter) / 100;
                var showAtDistance = ($(document).height() * settings.showAt) / 100;

                if (st > lastScrollTop) { 
                    // Scroll down
                    // console.log('scroll down');
                    if (st > hideAfterDistance) {
                        // console.log('st > hideAfterDistance =>' + hideAfterDistance);
                        $header.removeClass('fixed-top');
                       
                    }else{
                        $header.addClass('fixed-top');
                    }
                } else { 
                    // Scroll up
                    // console.log('scroll up => remove fixed top');
                    $header.addClass('fixed-top');
                }

                if(st == 0){
                    $header.removeClass('fixed-top');
                }

                lastScrollTop = st;
            });

        });
    }
})(jQuery);
