$(document).ready(function () {
    common();
    goToTop();
   
    loadGoogleFont();
    jumpMenu();
    //index
    bannerSlider();
    bgImagesLazyload();
    lazyMap();
    appWidgetFeed($('.section-google'));
    //blog
    blogSharing();
});

function jumpMenu() {

    var jumpOffset = -$('nav').height();
    console.log('jumpMenu => offset:' + jumpOffset);

    //選單跳段落
    $('nav a').click(function () {

        console.log('click menu link:' + this.hash);

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash, jumpOffset, 500);
        }
        // return false;
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:');
        Pace.on('done', function () {
            jumpSection(newjumpHash, jumpOffset, 500);
            // return false;
        });
    }

}

function goToTop() {

    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $rootElemHtmlBody = $('html, body');
    $scrollToTop.click(function () {
        $rootElemHtmlBody.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function() {
    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        setMasterSliderImageOnScreen($bannerSlider);

        try {
            bSlider = new MasterSlider();
            // adds Arrows navigation control to the slider.

            bSlider.control('timebar', {
                insertTo: '#bannerSlider'
            });
            bSlider.control('bullets');

            bSlider.control('circletimer', {
                autohide: false,
                overVideo: true,
                color: '#FFFFFF',
                radius: 4,
                stroke: 9
            });

            bSlider.control('arrows', {
                autohide: true
            });
            bSlider.setup('bannerSlider', {
                width: 1440, // slider standard width
                height: 730, // slider standard height
                minHeight: 400,
                start: 1,
                space: 0,
                layout: 'fullwidth',
                loop: true,
                preload: 0,
                instantStartLayers: false,
                autoplay: true,
                overPause: true,
                view: 'fadeBasic'
            });
            // $(window).trigger('resize');
            $('.master-skeleton-loader').remove();


        } catch (err) {
            console.log(err);
            removeErrorMasterSliderContainer($bannerSlider);
        }
    }
    // });
}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook", "linkedin"]
        });
    }
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Noto Serif TC:100,300,500,700,900&display=swap', 'Roboto&display=swap'
                ]
            }
        });
    });
}

function owlSliderEnableLazyload($owlSlider) {
    console.log('owlSliderEnableLazyload');
    if ($owlSlider.length > 0) {
        var isFired = false;

        var $owlImages = $owlSlider.find('img');
        $owlSlider.on('inview', function (event, isInView) {
            if (isFired == false) {
                isFired = true;
                startLoadImages($owlImages);
            }
        });
    }

}

function bgImagesLazyload() {
    initLazyload($('.treatment'));
}

// function lazyYoutube() {
//     Pace.on('done', function () {
//         initViewLazyOfIframe($('#homeYt'));
//     });
// }

function lazyMap() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeMap'));
    });
}

function initViewLazyOfIframe($targetElem) {

    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {
            if (isInView) {
                if ($targetElem.data('isEnable') != true) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    $targetElem.data('isEnable', true);
                }
            }
        });
    }
}

function appWidgetFeed($initLoadElemWrapper) {
    if ($initLoadElemWrapper.length > 0) {
        var isEnable = false;
        $initLoadElemWrapper.on('inview', function (event, isInView) {
            if (isInView && isEnable == false) {
                isEnable = true;
                var appWidgets = document.createElement("script");
                appWidgets.async = true;
                if (window.MYAPP.hasOwnProperty('apps_widget_endpoint')) {
                    appWidgets.src = window.MYAPP.apps_widget_endpoint;
                    $("head").append(appWidgets);
                }
            }
        });
    }
}